<template>
  <div>
    <div v-if="label" class="base-upload-label">
      {{ label }}
    </div>
    <div class="base-upload" v-loading="isLoading">
      <div class="base-upload-file">
        <div class="base-upload-file-preview">
          <template v-if="formValue">
            <div
              class="base-upload-file-preview-image"
              v-bg-image-from-storage="modelValue ?? image_path"
            />
          </template>
          <inline-svg
            v-else
            class="base-upload-file-preview-placeholder"
            :src="require('@/assets/icons/image-placeholder.svg')"
          />
        </div>
        <div class="base-upload-placeholder">
          {{ placeholder ? placeholder : "Choose an image" }}
        </div>
        <div v-if="formValue" class="base-upload-file-name">
          {{ formValue.replace("images/", "") }}
        </div>
      </div>
      <el-upload
        ref="upload"
        class="base-upload-input"
        action=""
        :multiple="false"
        :http-request="onSubmit"
        :before-upload="beforeUpload"
        :show-file-list="false"
        :limit="1"
      >
        <div class="base-upload-button">Choose</div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import InlineSvg from "vue-inline-svg";
import { useToast } from "vue-toastification";

export default {
  name: "BaseUpload",
  props: ["modelValue", "placeholder", "label", "dimensions"],
  components: {
    InlineSvg,
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      image_path: "",
      isLoading: false,
      maximumFileSize: 6,
    };
  },
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async getPreviewImage(url) {
      await firebase
        .storage()
        .ref()
        .child(url)
        .getDownloadURL()
        .then((path) => (this.image_path = path));
    },
    async onSubmit(req) {
      this.isLoading = true;
      const uuid = Math.random().toString(36).slice(-6);
      const imageName = req.file.name.replace(/ /g, "-");
      const bucket_url = `${uuid}_${imageName}`;
      const ref = await firebase.storage().ref().child(bucket_url);
      await ref.put(req.file);

      this.formValue = bucket_url;
      this.image_path = bucket_url;

      this.isLoading = false;
    },
    async beforeUpload(file) {
      this.$refs.upload.clearFiles();

      // Check file size
      const uploadExceedsMaximumFileSize = file.size / 1024 / 1024 > this.maximumFileSize;
      if (uploadExceedsMaximumFileSize) {
        this.toast.error(`Image size can not exceed ${this.maximumFileSize} MB!`);
        return Promise.reject();
      }

      // Check file type
      const validType = file.type.split("/")[0] === "image"; // Must be an image
      if (!validType) {
        this.toast.error("File must be an image!");
        return Promise.reject();
      }

      // Check image dimensions
      let validDimensions = true;
      if (this.dimensions) {
        const imageDimensions = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function () {
            const img = new Image();
            img.onload = function () {
              resolve([img.width, img.height]);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        });

        const dimensionsArray = this.dimensions.split("x");

        for (const i of [0, 1]) {
          // 0 means "any"
          if (dimensionsArray[i] !== "0" && validDimensions) {
            if (dimensionsArray[i].includes(">")) {
              // More
              if (Number(imageDimensions[i]) <= Number(dimensionsArray[i].split(">")[1])) {
                validDimensions = false;
                this.toast.error(
                  `Image should have ${i === 0 ? "width" : "height"} > ${
                    dimensionsArray[i].split(">")[1]
                  }!`
                );
              }
            } else if (dimensionsArray[i].includes("<")) {
              // Less
              if (Number(imageDimensions[i]) >= Number(dimensionsArray[i].split(">")[1])) {
                validDimensions = false;
                this.toast.error(
                  `Image should have ${i === 0 ? "width" : "height"} < ${
                    dimensionsArray[i].split(">")[1]
                  }!`
                );
              }
            } else if (String(imageDimensions[i]) !== dimensionsArray[i]) {
              // Equal
              validDimensions = false;
              this.toast.error(`Image should have size ${this.dimensions}!`);
            }
          }
        }

        if (!validDimensions) {
          return Promise.reject();
        }
      }

      return Promise.resolve();
    },
  },
};
</script>

<style lang="scss" scoped>
.base-upload {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 12px 12px;
  outline: none;
  background: #fff;
  transition: border-color 0.2s ease;
  will-change: border-color;
  gap: 12px;
}

.base-upload-label {
  margin-bottom: 12px;
  color: #878d93;
  font-size: 14px;
}

.base-upload-file {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
}

.base-upload-file-preview {
  border-radius: 4px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  position: relative;
}

.base-upload-file-preview-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;
}

.base-upload-file-preview-placeholder {
  width: 100%;
  height: 100%;
}

.base-upload-file-name {
  opacity: 0.5;
  font-size: 13px;
  max-width: 150px;
  width: auto;
}

.base-upload-placeholder {
  flex: 1;
  width: 0;
}

.base-upload-file-name,
.base-upload-placeholder {
  font-style: normal;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.base-upload-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
}
</style>
