<template>
  <div v-loading="isLoading" class="settings">
    <div class="settings-container" v-if="uid">
      <div v-if="uid" class="settings-title">Club QR Code</div>
      <div v-if="uid" class="settings-qr">
        <div class="settings-qr-code">
          <canvas id="preview-qrcode" ref="preview-qrcode" />
        </div>
        <div class="settings-link">
          Club web-app link:
          <a :href="`${baseURL}/${this.uid}`" target="_blank">{{ `${baseURL}/${this.uid}` }}</a>
        </div>
        <div class="settings-qr-button">
          <BaseButton @click="handleDownloadCode"> Download QR </BaseButton>
        </div>
      </div>
      <div class="settings-title">Club details</div>
      <div class="settings-margin">
        <BaseInput v-model="club.name" placeholder="Club name" label="Club name" />
      </div>
      <div class="settings-margin">
        <BaseInput v-model="club.welcome_title" placeholder="Welcome title" label="Welcome title" />
      </div>
      <div class="settings-margin">
        <BaseRichText v-model="club.welcome_message" title="Welcome message" />
      </div>
      <div class="settings-admin">
        <div class="settings-title">Club admin account</div>
        <div class="settings-margin" :class="{ 'settings-row': !uid }">
          <BaseInput v-model="club.email" label="Email" placeholder="Email" :disabled="uid" />
        </div>
        <div class="settings-margin" :class="{ 'settings-row': !uid }">
          <BaseInput
            v-model="club.phone_number"
            label="Phone number"
            placeholder="Enter contact phone number"
          />
        </div>
        <div
          v-if="club.phone_number !== '' && !validatePhone(club.phone_number)"
          class="error-block"
        >
          Please, type correct phone number
        </div>
      </div>
      <div class="settings-colors">
        <div class="settings-title">Club colors</div>
        <div class="settings-row settings-margin">
          <div>
            <div class="label">Primary fore color</div>
            <BaseColorPicker
              v-model="club.primary_fore_color"
              placeholder="Pick or paste primary fore color"
            />
          </div>
          <div>
            <div class="label">Primary back color</div>
            <BaseColorPicker
              v-model="club.primary_back_color"
              placeholder="Pick or paste primary back color"
            />
          </div>
          <div>
            <div class="label">Secondary fore color</div>
            <BaseColorPicker
              v-model="club.secondary_fore_color"
              placeholder="Pick or paste secondary fore color"
            />
          </div>
        </div>
        <div class="settings-row">
          <div>
            <div class="label">Secondary back color</div>
            <BaseColorPicker
              v-model="club.secondary_back_color"
              placeholder="Pick or paste secondary back color"
            />
          </div>
          <div>
            <div class="label">Disabled fore color</div>
            <BaseColorPicker
              v-model="club.disabled_fore_color"
              placeholder="Pick or paste disabled fore color"
            />
          </div>
        </div>
      </div>

      <!-- Passkit -->
      <div class="create-club-passkit">
        <div class="create-club-title">Passkit Card Styling</div>
        <div class="passkit-wrapper">
          <div class="left">
            <!-- Lockscreen -->
            <div>
              <div class="label">Lockscreen Icon</div>
              <div class="small-description">
                The lockscreen icon is mandatory and must be 1:1 ratio (square). The minimum
                required size is 114px x 114px at 72dpi in PNG or JPG format.
              </div>
              <BaseUpload
                v-model="club.card_style.lockscreen_icon"
                placeholder="Lockscreen Icon"
                dimensions="114x114"
              />
            </div>

            <!-- iOS Logo -->
            <div>
              <div class="label">Logo iOS</div>
              <div class="small-description">
                You can upload a square or rectangular logo for your iOS wallet pass. The
                recommended dimensions for a rectangular logo are 480px x 150px at 72dpi in PNG or
                JPG format.
              </div>
              <BaseUpload
                v-model="club.card_style.ios_logo"
                placeholder="iOS Logo"
                dimensions="480x150"
              />
            </div>

            <!-- Android Logo -->
            <div>
              <div class="label">Logo Android</div>
              <div class="small-description">
                You can upload a square logo for your Android wallet pass. The recommended
                dimensions for a square logo are 660px x 660px at 72dpi in PNG or JPG format.
              </div>
              <BaseUpload
                v-model="club.card_style.android_logo"
                placeholder="Android Logo"
                dimensions="660x660"
              />
            </div>

            <!-- Hero Image -->
            <div>
              <div class="label">Hero Image</div>
              <div class="small-description">
                The hero image is the large banner image across your wallet pass. This is the best
                place to add your unique branding. The recommended dimensions are 1125px x 432px at
                72dpi in PNG or JPG format.
              </div>
              <BaseUpload
                v-model="club.card_style.hero_image"
                dimensions=">1124x432"
                placeholder="Hero Image"
              />
            </div>

            <!-- Background color -->
            <div>
              <div class="label">Background color</div>
              <BaseColorPicker
                v-model="club.card_style.background_color"
                placeholder="Background color"
              />
            </div>

            <!-- Label -->
            <div>
              <div class="label">Label color</div>
              <BaseColorPicker v-model="club.card_style.label_color" placeholder="Label color" />
            </div>

            <!-- Text color -->
            <div>
              <div class="label">Text color</div>
              <BaseColorPicker v-model="club.card_style.text_color" placeholder="Text color" />
            </div>
          </div>

          <div class="right">
            <PasskitPreview :club="club" :card-style="club.card_style" />
          </div>
        </div>
      </div>

      <div class="settings-images">
        <div class="settings-title">Web App Club Branding</div>
        <div class="settings-margin">
          <div class="label">Logo</div>
          <div class="small-description">
            To be displayed on the web-app at the top of most pages. The recommended dimensions are
            320px x 320px at 72dpi in PNG or JPG format.
            <BaseUpload v-model="club.logo_image" placeholder="Choose logo" />
          </div>
        </div>
        <div class="settings-margin">
          <div class="label">Splash image</div>
          <div class="small-description">
            To be displayed on the web-app welcome page. The recommended dimensions are 400px x
            225px at 72dpi in PNG or JPG format.
            <BaseUpload v-model="club.splash_image" placeholder="Choose splash image" />
          </div>
        </div>
        <div class="settings-margin">
          <div class="label">Wallet image</div>
          <div class="small-description">
            To be displayed on the web-app when previewing what the wallet pass may look like. The
            recommended dimensions are 400px x 195px at 72dpi in PNG or JPG format.
          </div>
          <BaseUpload v-model="club.wallet_image" placeholder="Choose wallet image" />
        </div>
        <div class="settings-margin">
          <div class="label">Subscription background</div>
          <div class="small-description">
            To be displayed on the web-app when showing a subscription product. The recommended
            dimensions are 420px x 160px at 72dpi in PNG or JPG format.
          </div>
          <BaseUpload
            v-model="club.subscription_image"
            placeholder="Choose subscription background"
          />
        </div>
      </div>
      <div class="settings-additional">
        <div class="settings-title">Club medical information</div>
        <div class="settings-row-2 settings-margin">
          <div class="settings-card">
            <div class="settings-card-title">Medical conditions</div>
            <div
              class="settings-card-input"
              v-for="(option, index) in club.medical_conditions"
              :key="`medical-option-${index}`"
            >
              <BaseInput v-model="club.medical_conditions[index]" />
              <div class="settings-remove" @click="handleRemoveMedicalCondition(index)">
                <inline-svg
                  class="settings-remove-icon"
                  :src="require('@/assets/icons/tag-remove.svg')"
                />
              </div>
            </div>
            <div class="settings-card-button" @click="handleAddMedicalCondition">Add answer</div>
          </div>
          <div class="settings-card">
            <div class="settings-card-title">Allergies</div>
            <div
              class="settings-card-input"
              v-for="(option, index) in club.allergies"
              :key="`medical-option-${index}`"
            >
              <BaseInput v-model="club.allergies[index]" />
              <div class="settings-remove" @click="handleRemoveAllergy(index)">
                <inline-svg
                  class="settings-remove-icon"
                  :src="require('@/assets/icons/tag-remove.svg')"
                />
              </div>
            </div>
            <div class="settings-card-button" @click="handleAddAllergy">Add answer</div>
          </div>
        </div>
      </div>
      <div class="settings-questions">
        <div class="settings-title">Club questions</div>
        <div
          class="settings-question"
          v-for="(question, index) in club.questions"
          :key="`question-${index}`"
        >
          <BaseInput v-model="question.text" :placeholder="`Question ${index + 1}`" />
          <BaseCheckbox v-model="question.required" label="Mandatory" />
          <div class="settings-remove" @click="handleRemoveQuestion(index)">
            <inline-svg
              class="settings-remove-icon"
              :src="require('@/assets/icons/tag-remove.svg')"
            />
            Remove
          </div>
        </div>
        <div class="settings-card-button" @click="handleAddQuestion">Add question</div>
      </div>
      <div class="settings-documents">
        <div class="settings-title">Club documents</div>
        <div class="settings-row-2">
          <BaseRichText v-model="club.terms_and_conditions" title="Terms and Conditions" />
          <BaseRichText v-model="club.privacy_policy" title="Privacy Policy" />
        </div>
      </div>
      <div class="settings-button">
        <BaseButton @click="handleUpdate" :disabled="disableButton"> Apply changes </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import BaseCheckbox from "@/components/BaseCheckbox";
import BaseColorPicker from "@/components/BaseColorPicker";
import BaseUpload from "@/components/BaseUpload";
import BaseRichText from "@/components/BaseRichText";

import InlineSvg from "vue-inline-svg";
import QRCode from "qrcode";
import firebase from "@/config/firebase";
import PasskitPreview from "../components/PasskitPreview/PasskitPreview";

export default {
  name: "Settings",
  components: {
    PasskitPreview,
    BaseButton,
    BaseInput,
    BaseCheckbox,
    BaseColorPicker,
    BaseUpload,
    BaseRichText,
    InlineSvg,
  },
  data() {
    return {
      qrCodeError: false,
      isLoading: false,
      not_required_fields: ["stripe_account_id", "questions"],
      club: {
        name: "",
        logo_image: "",
        splash_image: "",
        phone_number: "",
        wallet_image: "",
        subscription_image: "",
        welcome_title: "",
        welcome_message: "",
        primary_fore_color: "",
        primary_back_color: "",
        secondary_back_color: "",
        disabled_fore_color: "",
        secondary_fore_color: "",
        allergies: [],
        medical_conditions: [],
        questions: [],
        terms_and_conditions: "",
        privacy_policy: "",
        stripe_account_id: null,
        card_style: {
          lockscreen_icon: "",
          ios_logo: "",
          android_logo: "",
          hero_image: "",
          background_color: "",
          label_color: "",
          text_color: "",
        },
      },
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
    clubData() {
      return this.$store.getters["club"];
    },
    uid() {
      return this.$store.getters["clubId"];
    },
    computedClub() {
      const style = this.club.card_style;
      const club = JSON.parse(JSON.stringify(this.club));
      delete club.card_style;
      delete club.subscriptions;
      delete club.images;
      delete club["colors"];

      return {
        ...club,
        ...style,
      };
    },
    computedClubRequiredOnly() {
      return Object.fromEntries(
        Object.entries(this.computedClub).filter(([key]) => !this.not_required_fields.includes(key))
      );
    },
    disableButton() {
      const anyClubVerifications = Object.values(this.computedClubRequiredOnly).every(
        (value) => value.length !== 0
      );

      return !anyClubVerifications && !this.validatePhone(this.club.phone_number);
    },
  },
  watch: {
    computedClub(value) {
      console.log(value);
      console.log(this.club);
      console.log("-----------");
    },
    clubData: function (current, prev) {
      if (current.id !== null && current.id !== prev.id) {
        this.getClub(current.id);
      }
    },
    uid: {
      handler(value) {
        if (value?.length > 0) {
          this.getClub(this.uid);
          this.$nextTick(() => {
            this.generateQRCode();
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    validatePhone(phone) {
      let re = /^\+?[1-9]\d{1,14}$/;
      return re.test(phone);
    },
    generateQRCode() {
      const qrCodeCanvas = this.$refs["preview-qrcode"];
      QRCode.toCanvas(qrCodeCanvas, `${process.env.VUE_APP_BASE_URL}/${this.uid}`, (error) => {
        if (error) {
          this.qrCodeError = true;
          console.error({ QRCodeGenerationError: error });
        }
      });
    },
    handleDownloadCode() {
      const canvas = this.$refs["preview-qrcode"];
      let link = document.createElement("a");
      link.download = "qr-code.png";
      link.href = canvas.toDataURL();
      link.click();
      link.delete;
    },
    handleAddQuestion() {
      this.club.questions.push({ text: "", required: false });
    },
    handleAddMedicalCondition() {
      this.club.medical_conditions.push("");
    },
    handleAddAllergy() {
      this.club.allergies.push("");
    },
    handleRemoveQuestion(index) {
      this.club.questions = this.club.questions
        .slice(0, index)
        .concat(this.club.questions.slice(index + 1, this.club.questions.length));
    },
    handleRemoveMedicalCondition(index) {
      this.club.medical_conditions = this.club.medical_conditions
        .slice(0, index)
        .concat(this.club.medical_conditions.slice(index + 1, this.club.medical_conditions.length));
    },
    handleRemoveAllergy(index) {
      this.club.allergies = this.club.allergies
        .slice(0, index)
        .concat(this.club.allergies.slice(index + 1, this.club.allergies.length));
    },
    async getClub() {
      this.club = {
        ...this.club,
        ...this.clubData,
      };
    },
    async handleUpdate() {
      this.isLoading = true;
      const updateClubCard = await firebase.functions().httpsCallable("updateClubCard");
      const cardEntity = await updateClubCard({
        card_style: this.club.card_style,
        club_id: this.uid,
      });

      if (cardEntity.data.isSuccess) {
        await firebase.firestore().collection("clubs").doc(this.uid).update(this.club);
      } else {
        this.toast.error(
          "Sorry, club update was not successful, please try again or contact support"
        );
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 12px;
  color: #878d93;
  font-size: 14px;
}

.settings-container {
  width: 1110px;
  padding: 32px 0 90px;
  margin: 0 auto;
}

.settings-qr {
  margin-bottom: 54px;
}

.settings-qr-code {
  margin-bottom: 24px;
  display: inline-block;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
}

.settings-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.settings-questions,
.settings-subscriptions,
.settings-colors,
.settings-images,
.settings-admin,
.settings-additional,
.settings-documents {
  margin-top: 54px;
}

.settings-question {
  margin-bottom: 25px;
  gap: 24px;
  display: flex;
  align-items: center;
}

.settings-button {
  margin-top: 54px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.settings-margin {
  margin-bottom: 25px;
}

.settings-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.settings-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.settings-card {
  background: #ffffff;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 8px;
  padding: 24px;
}

.settings-card-input {
  margin-bottom: 25px;
  display: flex;
  gap: 16px;
}

.settings-card-input:last-child {
  margin-bottom: 0;
}

.settings-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.settings-remove {
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.settings-remove-icon {
  height: 24px;
  width: 24px;
  border-radius: 100px;
  :deep(path) {
    fill: #f7685b;
  }
}

.settings-question .settings-remove {
  height: 32px;
  border-left: 1px solid rgba(166, 170, 180, 0.5);
  padding-left: 24px;
  flex-flow: row nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
}

.settings-question .settings-remove-icon {
  margin-right: 12px;
}

.settings-card-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 8px 24px;
  cursor: pointer;
  display: inline-block;
}

.settings-link {
  margin-bottom: 20px;

  a {
    font-weight: 500;
    color: #0ba3a9;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.5;
    }
  }
}

.create-club-passkit {
  margin-top: 40px;
}

.passkit-wrapper {
  display: flex;

  .left {
    display: flex;
    flex-direction: column;

    > div {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  .right {
    margin-left: 80px;
  }
}

.small-description {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1.5;
}

.create-club-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.error-block {
  color: #de1534;
  padding: 10px 15px;
  border-radius: 5px;
  background: #ffedf3;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 25px;
}
</style>

<style>
.settings .el-loading-spinner {
  position: fixed;
}
</style>
